// ======================================================================
// Forge Lite | hello@hashandsalt.com
// ======================================================================



// window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');



// Look for .hamburger
var hamburger = document.querySelector(".hamburger");
var menu = document.querySelector(".site-menu");
var bodyclasses = document.body;


function menuTogggle() {
      // If active...
      if (menu.classList.contains('site-menu-active')) {
        // Hide
        menu.classList.remove('site-menu-active');
        menu.setAttribute('aria-hidden', 'true');
        hamburger.classList.remove('is-active');
        hamburger.setAttribute('aria-expanded', 'false');
  
        bodyclasses.classList.remove('no-scroll');
        bodyclasses.classList.remove('menu-open');
  
      } else {
        // Show
        menu.classList.add('site-menu-active');
        menu.setAttribute('aria-hidden', 'false');
        hamburger.classList.add('is-active');
        hamburger.setAttribute('aria-expanded', 'true');
  
        bodyclasses.classList.add('no-scroll');
        bodyclasses.classList.add('menu-open');
  
        // Set focus on first link
        // menu.children[0].children[0].children[0].focus();
      }
  
}



if (hamburger) {
  hamburger.addEventListener('click', function() {
    menuTogggle()
  }, false);

}

var menulinks = document.querySelectorAll('.site-menu a');



if (menulinks) {
  menulinks.forEach(item => {
    item.addEventListener('click', event => {
      menuTogggle();
    })
  })
}



// unmute video
var video = document.getElementById('videobox');

if (video) {

  function toggleMute(video) {
    var audioElm = document.getElementById('videobox');
    audioElm.muted = !audioElm.muted;

    document.getElementById('videobtn').classList.toggle('unmuted');

  }
  document.getElementById('videobtn').addEventListener('click', toggleMute);
}



// Modal 
// var MicroModal = require('micromodal');
// MicroModal.init();

var shirts = document.querySelectorAll('.tshirt-select');

if (shirts) {
document.querySelectorAll('.tshirt-select').forEach(item => {
  item.addEventListener('change', event => {
    let butt = document.getElementById(event.target.id + '-butt');
    butt.setAttribute("data-item-custom1-value", event.target.value.toUpperCase());
  })
})
}


// Image Swap
// -------------------------------------------
// -------------------------------------------
function imgSwap() {
  let swapimgs = document.querySelectorAll('.swapsrc');

  swapimgs.forEach(function(swapitem) {

    let swapimgorg = swapitem.src;
    let swapimghvy = swapitem.getAttribute('data-swap');

    swapitem.addEventListener("mouseenter", function() {
      this.src = swapimghvy;
    }, false);

    swapitem.addEventListener("mouseleave", function() {
      this.src = swapimgorg;
    }, false);
  });
}


if (shirts) {
  imgSwap();
}



